export const useCardSettings = () => {
  const getCardSettings = (model) => {
    const type = model.model_subtype ?? model.model_type;
    const result = {};
    switch (type) {
      case 'monument': {
        Object.assign(result, {
          label: 'Памятник',
          labelColor: 'text-article',
          labelBgColor: 'bg-article',
          hrefBase: '/read/monuments/',
          defaultCard: 'ArticleCard',
          coverCard: 'ArticleCoverCard',
        });
        break;
      }
      case 'science': {
        Object.assign(result, {
          label: 'Наука',
          labelColor: 'text-article',
          labelBgColor: 'bg-article',
          hrefBase: '/read/science/',
          defaultCard: 'ArticleCard',
          coverCard: 'ArticleCoverCard',
        });
        break;
      }
      case 'biography':
        Object.assign(result, {
          label: 'Биография',
          labelColor: 'text-biography',
          labelBgColor: 'bg-biography',
          hrefBase: '/read/biographies/',
          defaultCard: 'BiographyCard',
          coverCard: 'BiographyCoverCard',
        });
        break;
      case 'wiki':
      case 'document_collection':
      case 'article':
        Object.assign(result, {
          label: 'Статья',
          labelColor: 'text-article',
          labelBgColor: 'bg-article',
          hrefBase: '/read/articles/',
          defaultCard: 'ArticleCard',
          coverCard: 'ArticleCoverCard',
        });
        break;
      case 'film':
        Object.assign(result, {
          label: 'Фильм',
          labelColor: 'text-videolecture',
          labelBgColor: 'bg-videolecture',
          hrefBase: '/watch/films/',
          defaultCard: 'LectureCard',
          coverCard: 'LectureCoverCard',
        });
        break;
      case 'lecture':
      case 'videolecture':
        Object.assign(result, {
          label: 'Видео',
          labelColor: 'text-videolecture',
          labelBgColor: 'bg-videolecture',
          hrefBase: '/watch/lectures/',
          defaultCard: 'LectureCard',
          coverCard: 'LectureCoverCard',
        });
        break;
      case 'news':
        Object.assign(result, {
          label: '', // Новость
          labelColor: '', // text-news
          labelBgColor: '', // bg-news
          hrefBase: '/read/news/',
          defaultCard: 'defaultCard',
          coverCard: '',
        });
        break;
      case 'podcast':
        Object.assign(result, {
          label: 'Подкаст',
          labelColor: 'text-audiolecture',
          labelBgColor: 'bg-audiolecture',
          hrefBase: '/listen/lectures/',
          defaultCard: 'PodcastCard',
          coverCard: '',
        });
        break;
      case 'document':
        Object.assign(result, {
          label: 'Документ',
          hrefBase: '/read/documents/',
          defaultCard: '',
          coverCard: '',
        });
        break;
      case 'audiomaterial':
      case 'audiolecture':
        Object.assign(result, {
          label: 'Аудиолекция',
          labelColor: 'text-audiolecture',
          labelBgColor: 'bg-audiolecture',
          hrefBase: '/listen/lectures/',
          defaultCard: 'LectureCard',
          coverCard: 'LectureCoverCard',
        });
        break;
      case 'test':
      case 'test_result':
        Object.assign(result, {
          label: 'Тест',
          labelColor: 'text-collection',
          labelBgColor: 'bg-collection',
          hrefBase: '/tests/',
          defaultCard: 'TestCard',
          coverCard: 'TestCoverCard',
        });
        break;
      case 'teacher':
        Object.assign(result, {
          label: 'Учителю и ученику',
          labelColor: 'text-course',
          labelBgColor: 'bg-course',
          hrefBase: '/teacher/',
          defaultCard: '',
          coverCard: '',
        });
        break;
      case 'teacher-video':
        Object.assign(result, {
          label: 'Учителю и ученику',
          labelColor: 'text-course',
          labelBgColor: 'bg-course',
          hrefBase: '/teacher/',
          defaultCard: '',
          coverCard: '',
        });
        break;
      case 'afisha':
        Object.assign(result, {
          label: 'Событие',
          labelColor: 'text-afisha',
          labelBgColor: 'bg-afisha',
          hrefBase: '/poster/',
          defaultCard: 'EventCard',
          coverCard: 'EventCoverCard',
        });
        break;
      case 'highlight':
        Object.assign(result, {
          label: 'Подборка',
          labelColor: 'text-collection',
          labelBgColor: 'bg-collection',
          hrefBase: '/collections/',
          defaultCard: 'CollectionCard',
          coverCard: '',
        });
        break;
      case 'course':
        Object.assign(result, {
          label: 'Курс',
          labelColor: 'text-course',
          labelBgColor: 'bg-course',
          hrefBase: '/read/courses/',
          defaultCard: 'CourseCard',
          coverCard: 'CourseCoverCard',
        });
        break;
      case 'videocourse':
        Object.assign(result, {
          label: 'Видеокурс',
          labelColor: 'text-course',
          labelBgColor: 'bg-course',
          hrefBase: '/watch/courses/',
          defaultCard: 'CourseCard',
          coverCard: 'CourseCoverCard',
        });
        break;
      case 'audiocourse':
        Object.assign(result, {
          label: 'Подкаст',
          labelColor: 'text-course',
          labelBgColor: 'bg-course',
          hrefBase: '/listen/courses/',
          defaultCard: 'CourseCard',
          coverCard: 'CourseCoverCard',
        });
        break;
      case 'specialproject':
        Object.assign(result, {
          label: 'Спецпроект',
          labelColor: 'text-specialproject',
          labelBgColor: 'bg-specialproject',
          hrefBase: '',
          defaultCard: '',
          coverCard: '',
        });
        break;
      case 'specialprojectsection':
        Object.assign(result, {
          label: 'Спецпроект',
          labelColor: 'text-specialprojectsection',
          labelBgColor: 'bg-specialprojectsection',
          hrefBase: '',
          defaultCard: '',
          coverCard: '',
        });
        break;
      case 'author':
        Object.assign(result, {
          label: 'Автор',
          labelColor: 'text-author',
          labelBgColor: 'bg-author',
          hrefBase: '/authors/',
          defaultCard: 'MainPageAuthorCard',
          coverCard: '',
        });
        break;
      default:
    }
    return result;
  };

  return getCardSettings;
};
